var provincialCity = [
        {
            "id": "110000",
            "parentId": "0",
            "title": "北京",
            "value": "北京",
            "weight": 1,
            "children": [
                {
                    "id": "110100",
                    "parentId": "110000",
                    "title": "北京市",
                    "value": "北京市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "110000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "120000",
            "parentId": "0",
            "title": "天津",
            "value": "天津",
            "weight": 1,
            "children": [
                {
                    "id": "120100",
                    "parentId": "120000",
                    "title": "天津市",
                    "value": "天津市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "120000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "130000",
            "parentId": "0",
            "title": "河北省",
            "value": "河北省",
            "weight": 1,
            "children": [
                {
                    "id": "130100",
                    "parentId": "130000",
                    "title": "石家庄市",
                    "value": "石家庄市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "130000"
                },
                {
                    "id": "130200",
                    "parentId": "130000",
                    "title": "唐山市",
                    "value": "唐山市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "130000"
                },
                {
                    "id": "130300",
                    "parentId": "130000",
                    "title": "秦皇岛市",
                    "value": "秦皇岛市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "130000"
                },
                {
                    "id": "130400",
                    "parentId": "130000",
                    "title": "邯郸市",
                    "value": "邯郸市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "130000"
                },
                {
                    "id": "130500",
                    "parentId": "130000",
                    "title": "邢台市",
                    "value": "邢台市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "130000"
                },
                {
                    "id": "130600",
                    "parentId": "130000",
                    "title": "保定市",
                    "value": "保定市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "130000"
                },
                {
                    "id": "130700",
                    "parentId": "130000",
                    "title": "张家口市",
                    "value": "张家口市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "130000"
                },
                {
                    "id": "130800",
                    "parentId": "130000",
                    "title": "承德市",
                    "value": "承德市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "130000"
                },
                {
                    "id": "130900",
                    "parentId": "130000",
                    "title": "沧州市",
                    "value": "沧州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "130000"
                },
                {
                    "id": "131000",
                    "parentId": "130000",
                    "title": "廊坊市",
                    "value": "廊坊市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "130000"
                },
                {
                    "id": "131100",
                    "parentId": "130000",
                    "title": "衡水市",
                    "value": "衡水市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "130000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "140000",
            "parentId": "0",
            "title": "山西省",
            "value": "山西省",
            "weight": 1,
            "children": [
                {
                    "id": "140100",
                    "parentId": "140000",
                    "title": "太原市",
                    "value": "太原市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "140000"
                },
                {
                    "id": "140200",
                    "parentId": "140000",
                    "title": "大同市",
                    "value": "大同市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "140000"
                },
                {
                    "id": "140300",
                    "parentId": "140000",
                    "title": "阳泉市",
                    "value": "阳泉市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "140000"
                },
                {
                    "id": "140400",
                    "parentId": "140000",
                    "title": "长治市",
                    "value": "长治市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "140000"
                },
                {
                    "id": "140500",
                    "parentId": "140000",
                    "title": "晋城市",
                    "value": "晋城市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "140000"
                },
                {
                    "id": "140600",
                    "parentId": "140000",
                    "title": "朔州市",
                    "value": "朔州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "140000"
                },
                {
                    "id": "140700",
                    "parentId": "140000",
                    "title": "晋中市",
                    "value": "晋中市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "140000"
                },
                {
                    "id": "140800",
                    "parentId": "140000",
                    "title": "运城市",
                    "value": "运城市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "140000"
                },
                {
                    "id": "140900",
                    "parentId": "140000",
                    "title": "忻州市",
                    "value": "忻州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "140000"
                },
                {
                    "id": "141000",
                    "parentId": "140000",
                    "title": "临汾市",
                    "value": "临汾市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "140000"
                },
                {
                    "id": "141100",
                    "parentId": "140000",
                    "title": "吕梁市",
                    "value": "吕梁市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "140000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "150000",
            "parentId": "0",
            "title": "内蒙古自治区",
            "value": "内蒙古自治区",
            "weight": 1,
            "children": [
                {
                    "id": "150100",
                    "parentId": "150000",
                    "title": "呼和浩特市",
                    "value": "呼和浩特市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "150000"
                },
                {
                    "id": "150200",
                    "parentId": "150000",
                    "title": "包头市",
                    "value": "包头市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "150000"
                },
                {
                    "id": "150300",
                    "parentId": "150000",
                    "title": "乌海市",
                    "value": "乌海市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "150000"
                },
                {
                    "id": "150400",
                    "parentId": "150000",
                    "title": "赤峰市",
                    "value": "赤峰市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "150000"
                },
                {
                    "id": "150500",
                    "parentId": "150000",
                    "title": "通辽市",
                    "value": "通辽市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "150000"
                },
                {
                    "id": "150600",
                    "parentId": "150000",
                    "title": "鄂尔多斯市",
                    "value": "鄂尔多斯市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "150000"
                },
                {
                    "id": "150700",
                    "parentId": "150000",
                    "title": "呼伦贝尔市",
                    "value": "呼伦贝尔市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "150000"
                },
                {
                    "id": "150800",
                    "parentId": "150000",
                    "title": "巴彦淖尔市",
                    "value": "巴彦淖尔市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "150000"
                },
                {
                    "id": "150900",
                    "parentId": "150000",
                    "title": "乌兰察布市",
                    "value": "乌兰察布市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "150000"
                },
                {
                    "id": "152200",
                    "parentId": "150000",
                    "title": "兴安盟",
                    "value": "兴安盟",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "150000"
                },
                {
                    "id": "152500",
                    "parentId": "150000",
                    "title": "锡林郭勒盟",
                    "value": "锡林郭勒盟",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "150000"
                },
                {
                    "id": "152900",
                    "parentId": "150000",
                    "title": "阿拉善盟",
                    "value": "阿拉善盟",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "150000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "210000",
            "parentId": "0",
            "title": "辽宁省",
            "value": "辽宁省",
            "weight": 1,
            "children": [
                {
                    "id": "210100",
                    "parentId": "210000",
                    "title": "沈阳市",
                    "value": "沈阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "210000"
                },
                {
                    "id": "210200",
                    "parentId": "210000",
                    "title": "大连市",
                    "value": "大连市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "210000"
                },
                {
                    "id": "210300",
                    "parentId": "210000",
                    "title": "鞍山市",
                    "value": "鞍山市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "210000"
                },
                {
                    "id": "210400",
                    "parentId": "210000",
                    "title": "抚顺市",
                    "value": "抚顺市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "210000"
                },
                {
                    "id": "210500",
                    "parentId": "210000",
                    "title": "本溪市",
                    "value": "本溪市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "210000"
                },
                {
                    "id": "210600",
                    "parentId": "210000",
                    "title": "丹东市",
                    "value": "丹东市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "210000"
                },
                {
                    "id": "210700",
                    "parentId": "210000",
                    "title": "锦州市",
                    "value": "锦州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "210000"
                },
                {
                    "id": "210800",
                    "parentId": "210000",
                    "title": "营口市",
                    "value": "营口市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "210000"
                },
                {
                    "id": "210900",
                    "parentId": "210000",
                    "title": "阜新市",
                    "value": "阜新市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "210000"
                },
                {
                    "id": "211000",
                    "parentId": "210000",
                    "title": "辽阳市",
                    "value": "辽阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "210000"
                },
                {
                    "id": "211100",
                    "parentId": "210000",
                    "title": "盘锦市",
                    "value": "盘锦市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "210000"
                },
                {
                    "id": "211200",
                    "parentId": "210000",
                    "title": "铁岭市",
                    "value": "铁岭市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "210000"
                },
                {
                    "id": "211300",
                    "parentId": "210000",
                    "title": "朝阳市",
                    "value": "朝阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "210000"
                },
                {
                    "id": "211400",
                    "parentId": "210000",
                    "title": "葫芦岛市",
                    "value": "葫芦岛市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "210000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "220000",
            "parentId": "0",
            "title": "吉林省",
            "value": "吉林省",
            "weight": 1,
            "children": [
                {
                    "id": "220100",
                    "parentId": "220000",
                    "title": "长春市",
                    "value": "长春市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "220000"
                },
                {
                    "id": "220200",
                    "parentId": "220000",
                    "title": "吉林市",
                    "value": "吉林市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "220000"
                },
                {
                    "id": "220300",
                    "parentId": "220000",
                    "title": "四平市",
                    "value": "四平市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "220000"
                },
                {
                    "id": "220400",
                    "parentId": "220000",
                    "title": "辽源市",
                    "value": "辽源市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "220000"
                },
                {
                    "id": "220500",
                    "parentId": "220000",
                    "title": "通化市",
                    "value": "通化市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "220000"
                },
                {
                    "id": "220600",
                    "parentId": "220000",
                    "title": "白山市",
                    "value": "白山市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "220000"
                },
                {
                    "id": "220700",
                    "parentId": "220000",
                    "title": "松原市",
                    "value": "松原市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "220000"
                },
                {
                    "id": "220800",
                    "parentId": "220000",
                    "title": "白城市",
                    "value": "白城市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "220000"
                },
                {
                    "id": "222400",
                    "parentId": "220000",
                    "title": "延边朝鲜族自治州",
                    "value": "延边朝鲜族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "220000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "230000",
            "parentId": "0",
            "title": "黑龙江省",
            "value": "黑龙江省",
            "weight": 1,
            "children": [
                {
                    "id": "230100",
                    "parentId": "230000",
                    "title": "哈尔滨市",
                    "value": "哈尔滨市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "230000"
                },
                {
                    "id": "230200",
                    "parentId": "230000",
                    "title": "齐齐哈尔市",
                    "value": "齐齐哈尔市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "230000"
                },
                {
                    "id": "230300",
                    "parentId": "230000",
                    "title": "鸡西市",
                    "value": "鸡西市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "230000"
                },
                {
                    "id": "230400",
                    "parentId": "230000",
                    "title": "鹤岗市",
                    "value": "鹤岗市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "230000"
                },
                {
                    "id": "230500",
                    "parentId": "230000",
                    "title": "双鸭山市",
                    "value": "双鸭山市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "230000"
                },
                {
                    "id": "230600",
                    "parentId": "230000",
                    "title": "大庆市",
                    "value": "大庆市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "230000"
                },
                {
                    "id": "230700",
                    "parentId": "230000",
                    "title": "伊春市",
                    "value": "伊春市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "230000"
                },
                {
                    "id": "230800",
                    "parentId": "230000",
                    "title": "佳木斯市",
                    "value": "佳木斯市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "230000"
                },
                {
                    "id": "230900",
                    "parentId": "230000",
                    "title": "七台河市",
                    "value": "七台河市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "230000"
                },
                {
                    "id": "231000",
                    "parentId": "230000",
                    "title": "牡丹江市",
                    "value": "牡丹江市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "230000"
                },
                {
                    "id": "231100",
                    "parentId": "230000",
                    "title": "黑河市",
                    "value": "黑河市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "230000"
                },
                {
                    "id": "231200",
                    "parentId": "230000",
                    "title": "绥化市",
                    "value": "绥化市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "230000"
                },
                {
                    "id": "232700",
                    "parentId": "230000",
                    "title": "大兴安岭地区",
                    "value": "大兴安岭地区",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "230000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "310000",
            "parentId": "0",
            "title": "上海",
            "value": "上海",
            "weight": 1,
            "children": [
                {
                    "id": "310100",
                    "parentId": "310000",
                    "title": "上海市",
                    "value": "上海市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "310000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "320000",
            "parentId": "0",
            "title": "江苏省",
            "value": "江苏省",
            "weight": 1,
            "children": [
                {
                    "id": "320100",
                    "parentId": "320000",
                    "title": "南京市",
                    "value": "南京市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "320000"
                },
                {
                    "id": "320200",
                    "parentId": "320000",
                    "title": "无锡市",
                    "value": "无锡市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "320000"
                },
                {
                    "id": "320300",
                    "parentId": "320000",
                    "title": "徐州市",
                    "value": "徐州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "320000"
                },
                {
                    "id": "320400",
                    "parentId": "320000",
                    "title": "常州市",
                    "value": "常州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "320000"
                },
                {
                    "id": "320500",
                    "parentId": "320000",
                    "title": "苏州市",
                    "value": "苏州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "320000"
                },
                {
                    "id": "320600",
                    "parentId": "320000",
                    "title": "南通市",
                    "value": "南通市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "320000"
                },
                {
                    "id": "320700",
                    "parentId": "320000",
                    "title": "连云港市",
                    "value": "连云港市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "320000"
                },
                {
                    "id": "320800",
                    "parentId": "320000",
                    "title": "淮安市",
                    "value": "淮安市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "320000"
                },
                {
                    "id": "320900",
                    "parentId": "320000",
                    "title": "盐城市",
                    "value": "盐城市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "320000"
                },
                {
                    "id": "321000",
                    "parentId": "320000",
                    "title": "扬州市",
                    "value": "扬州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "320000"
                },
                {
                    "id": "321100",
                    "parentId": "320000",
                    "title": "镇江市",
                    "value": "镇江市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "320000"
                },
                {
                    "id": "321200",
                    "parentId": "320000",
                    "title": "泰州市",
                    "value": "泰州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "320000"
                },
                {
                    "id": "321300",
                    "parentId": "320000",
                    "title": "宿迁市",
                    "value": "宿迁市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "320000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "330000",
            "parentId": "0",
            "title": "浙江省",
            "value": "浙江省",
            "weight": 1,
            "children": [
                {
                    "id": "330100",
                    "parentId": "330000",
                    "title": "杭州市",
                    "value": "杭州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "330000"
                },
                {
                    "id": "330200",
                    "parentId": "330000",
                    "title": "宁波市",
                    "value": "宁波市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "330000"
                },
                {
                    "id": "330300",
                    "parentId": "330000",
                    "title": "温州市",
                    "value": "温州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "330000"
                },
                {
                    "id": "330400",
                    "parentId": "330000",
                    "title": "嘉兴市",
                    "value": "嘉兴市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "330000"
                },
                {
                    "id": "330500",
                    "parentId": "330000",
                    "title": "湖州市",
                    "value": "湖州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "330000"
                },
                {
                    "id": "330600",
                    "parentId": "330000",
                    "title": "绍兴市",
                    "value": "绍兴市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "330000"
                },
                {
                    "id": "330700",
                    "parentId": "330000",
                    "title": "金华市",
                    "value": "金华市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "330000"
                },
                {
                    "id": "330800",
                    "parentId": "330000",
                    "title": "衢州市",
                    "value": "衢州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "330000"
                },
                {
                    "id": "330900",
                    "parentId": "330000",
                    "title": "舟山市",
                    "value": "舟山市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "330000"
                },
                {
                    "id": "331000",
                    "parentId": "330000",
                    "title": "台州市",
                    "value": "台州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "330000"
                },
                {
                    "id": "331100",
                    "parentId": "330000",
                    "title": "丽水市",
                    "value": "丽水市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "330000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "340000",
            "parentId": "0",
            "title": "安徽省",
            "value": "安徽省",
            "weight": 1,
            "children": [
                {
                    "id": "340100",
                    "parentId": "340000",
                    "title": "合肥市",
                    "value": "合肥市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "340000"
                },
                {
                    "id": "340200",
                    "parentId": "340000",
                    "title": "芜湖市",
                    "value": "芜湖市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "340000"
                },
                {
                    "id": "340300",
                    "parentId": "340000",
                    "title": "蚌埠市",
                    "value": "蚌埠市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "340000"
                },
                {
                    "id": "340400",
                    "parentId": "340000",
                    "title": "淮南市",
                    "value": "淮南市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "340000"
                },
                {
                    "id": "340500",
                    "parentId": "340000",
                    "title": "马鞍山市",
                    "value": "马鞍山市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "340000"
                },
                {
                    "id": "340600",
                    "parentId": "340000",
                    "title": "淮北市",
                    "value": "淮北市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "340000"
                },
                {
                    "id": "340700",
                    "parentId": "340000",
                    "title": "铜陵市",
                    "value": "铜陵市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "340000"
                },
                {
                    "id": "340800",
                    "parentId": "340000",
                    "title": "安庆市",
                    "value": "安庆市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "340000"
                },
                {
                    "id": "341000",
                    "parentId": "340000",
                    "title": "黄山市",
                    "value": "黄山市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "340000"
                },
                {
                    "id": "341100",
                    "parentId": "340000",
                    "title": "滁州市",
                    "value": "滁州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "340000"
                },
                {
                    "id": "341200",
                    "parentId": "340000",
                    "title": "阜阳市",
                    "value": "阜阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "340000"
                },
                {
                    "id": "341300",
                    "parentId": "340000",
                    "title": "宿州市",
                    "value": "宿州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "340000"
                },
                {
                    "id": "341500",
                    "parentId": "340000",
                    "title": "六安市",
                    "value": "六安市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "340000"
                },
                {
                    "id": "341600",
                    "parentId": "340000",
                    "title": "亳州市",
                    "value": "亳州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "340000"
                },
                {
                    "id": "341700",
                    "parentId": "340000",
                    "title": "池州市",
                    "value": "池州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "340000"
                },
                {
                    "id": "341800",
                    "parentId": "340000",
                    "title": "宣城市",
                    "value": "宣城市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "340000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "350000",
            "parentId": "0",
            "title": "福建省",
            "value": "福建省",
            "weight": 1,
            "children": [
                {
                    "id": "350100",
                    "parentId": "350000",
                    "title": "福州市",
                    "value": "福州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "350000"
                },
                {
                    "id": "350200",
                    "parentId": "350000",
                    "title": "厦门市",
                    "value": "厦门市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "350000"
                },
                {
                    "id": "350300",
                    "parentId": "350000",
                    "title": "莆田市",
                    "value": "莆田市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "350000"
                },
                {
                    "id": "350400",
                    "parentId": "350000",
                    "title": "三明市",
                    "value": "三明市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "350000"
                },
                {
                    "id": "350500",
                    "parentId": "350000",
                    "title": "泉州市",
                    "value": "泉州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "350000"
                },
                {
                    "id": "350600",
                    "parentId": "350000",
                    "title": "漳州市",
                    "value": "漳州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "350000"
                },
                {
                    "id": "350700",
                    "parentId": "350000",
                    "title": "南平市",
                    "value": "南平市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "350000"
                },
                {
                    "id": "350800",
                    "parentId": "350000",
                    "title": "龙岩市",
                    "value": "龙岩市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "350000"
                },
                {
                    "id": "350900",
                    "parentId": "350000",
                    "title": "宁德市",
                    "value": "宁德市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "350000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "360000",
            "parentId": "0",
            "title": "江西省",
            "value": "江西省",
            "weight": 1,
            "children": [
                {
                    "id": "360100",
                    "parentId": "360000",
                    "title": "南昌市",
                    "value": "南昌市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "360000"
                },
                {
                    "id": "360200",
                    "parentId": "360000",
                    "title": "景德镇市",
                    "value": "景德镇市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "360000"
                },
                {
                    "id": "360300",
                    "parentId": "360000",
                    "title": "萍乡市",
                    "value": "萍乡市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "360000"
                },
                {
                    "id": "360400",
                    "parentId": "360000",
                    "title": "九江市",
                    "value": "九江市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "360000"
                },
                {
                    "id": "360500",
                    "parentId": "360000",
                    "title": "新余市",
                    "value": "新余市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "360000"
                },
                {
                    "id": "360600",
                    "parentId": "360000",
                    "title": "鹰潭市",
                    "value": "鹰潭市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "360000"
                },
                {
                    "id": "360700",
                    "parentId": "360000",
                    "title": "赣州市",
                    "value": "赣州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "360000"
                },
                {
                    "id": "360800",
                    "parentId": "360000",
                    "title": "吉安市",
                    "value": "吉安市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "360000"
                },
                {
                    "id": "360900",
                    "parentId": "360000",
                    "title": "宜春市",
                    "value": "宜春市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "360000"
                },
                {
                    "id": "361000",
                    "parentId": "360000",
                    "title": "抚州市",
                    "value": "抚州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "360000"
                },
                {
                    "id": "361100",
                    "parentId": "360000",
                    "title": "上饶市",
                    "value": "上饶市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "360000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "370000",
            "parentId": "0",
            "title": "山东省",
            "value": "山东省",
            "weight": 1,
            "children": [
                {
                    "id": "370100",
                    "parentId": "370000",
                    "title": "济南市",
                    "value": "济南市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "370000"
                },
                {
                    "id": "370200",
                    "parentId": "370000",
                    "title": "青岛市",
                    "value": "青岛市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "370000"
                },
                {
                    "id": "370300",
                    "parentId": "370000",
                    "title": "淄博市",
                    "value": "淄博市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "370000"
                },
                {
                    "id": "370400",
                    "parentId": "370000",
                    "title": "枣庄市",
                    "value": "枣庄市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "370000"
                },
                {
                    "id": "370500",
                    "parentId": "370000",
                    "title": "东营市",
                    "value": "东营市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "370000"
                },
                {
                    "id": "370600",
                    "parentId": "370000",
                    "title": "烟台市",
                    "value": "烟台市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "370000"
                },
                {
                    "id": "370700",
                    "parentId": "370000",
                    "title": "潍坊市",
                    "value": "潍坊市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "370000"
                },
                {
                    "id": "370800",
                    "parentId": "370000",
                    "title": "济宁市",
                    "value": "济宁市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "370000"
                },
                {
                    "id": "370900",
                    "parentId": "370000",
                    "title": "泰安市",
                    "value": "泰安市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "370000"
                },
                {
                    "id": "371000",
                    "parentId": "370000",
                    "title": "威海市",
                    "value": "威海市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "370000"
                },
                {
                    "id": "371100",
                    "parentId": "370000",
                    "title": "日照市",
                    "value": "日照市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "370000"
                },
                {
                    "id": "371200",
                    "parentId": "370000",
                    "title": "莱芜市",
                    "value": "莱芜市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "370000"
                },
                {
                    "id": "371300",
                    "parentId": "370000",
                    "title": "临沂市",
                    "value": "临沂市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "370000"
                },
                {
                    "id": "371400",
                    "parentId": "370000",
                    "title": "德州市",
                    "value": "德州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "370000"
                },
                {
                    "id": "371500",
                    "parentId": "370000",
                    "title": "聊城市",
                    "value": "聊城市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "370000"
                },
                {
                    "id": "371600",
                    "parentId": "370000",
                    "title": "滨州市",
                    "value": "滨州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "370000"
                },
                {
                    "id": "371700",
                    "parentId": "370000",
                    "title": "菏泽市",
                    "value": "菏泽市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "370000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "410000",
            "parentId": "0",
            "title": "河南省",
            "value": "河南省",
            "weight": 1,
            "children": [
                {
                    "id": "410100",
                    "parentId": "410000",
                    "title": "郑州市",
                    "value": "郑州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "410000"
                },
                {
                    "id": "410200",
                    "parentId": "410000",
                    "title": "开封市",
                    "value": "开封市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "410000"
                },
                {
                    "id": "410300",
                    "parentId": "410000",
                    "title": "洛阳市",
                    "value": "洛阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "410000"
                },
                {
                    "id": "410400",
                    "parentId": "410000",
                    "title": "平顶山市",
                    "value": "平顶山市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "410000"
                },
                {
                    "id": "410500",
                    "parentId": "410000",
                    "title": "安阳市",
                    "value": "安阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "410000"
                },
                {
                    "id": "410600",
                    "parentId": "410000",
                    "title": "鹤壁市",
                    "value": "鹤壁市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "410000"
                },
                {
                    "id": "410700",
                    "parentId": "410000",
                    "title": "新乡市",
                    "value": "新乡市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "410000"
                },
                {
                    "id": "410800",
                    "parentId": "410000",
                    "title": "焦作市",
                    "value": "焦作市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "410000"
                },
                {
                    "id": "410881",
                    "parentId": "410000",
                    "title": "济源市",
                    "value": "济源市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "410000"
                },
                {
                    "id": "410900",
                    "parentId": "410000",
                    "title": "濮阳市",
                    "value": "濮阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "410000"
                },
                {
                    "id": "411000",
                    "parentId": "410000",
                    "title": "许昌市",
                    "value": "许昌市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "410000"
                },
                {
                    "id": "411100",
                    "parentId": "410000",
                    "title": "漯河市",
                    "value": "漯河市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "410000"
                },
                {
                    "id": "411200",
                    "parentId": "410000",
                    "title": "三门峡市",
                    "value": "三门峡市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "410000"
                },
                {
                    "id": "411300",
                    "parentId": "410000",
                    "title": "南阳市",
                    "value": "南阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "410000"
                },
                {
                    "id": "411400",
                    "parentId": "410000",
                    "title": "商丘市",
                    "value": "商丘市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "410000"
                },
                {
                    "id": "411500",
                    "parentId": "410000",
                    "title": "信阳市",
                    "value": "信阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "410000"
                },
                {
                    "id": "411600",
                    "parentId": "410000",
                    "title": "周口市",
                    "value": "周口市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "410000"
                },
                {
                    "id": "411700",
                    "parentId": "410000",
                    "title": "驻马店市",
                    "value": "驻马店市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "410000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "420000",
            "parentId": "0",
            "title": "湖北省",
            "value": "湖北省",
            "weight": 1,
            "children": [
                {
                    "id": "420100",
                    "parentId": "420000",
                    "title": "武汉市",
                    "value": "武汉市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "420000"
                },
                {
                    "id": "420200",
                    "parentId": "420000",
                    "title": "黄石市",
                    "value": "黄石市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "420000"
                },
                {
                    "id": "420300",
                    "parentId": "420000",
                    "title": "十堰市",
                    "value": "十堰市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "420000"
                },
                {
                    "id": "420500",
                    "parentId": "420000",
                    "title": "宜昌市",
                    "value": "宜昌市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "420000"
                },
                {
                    "id": "420600",
                    "parentId": "420000",
                    "title": "襄阳市",
                    "value": "襄阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "420000"
                },
                {
                    "id": "420700",
                    "parentId": "420000",
                    "title": "鄂州市",
                    "value": "鄂州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "420000"
                },
                {
                    "id": "420800",
                    "parentId": "420000",
                    "title": "荆门市",
                    "value": "荆门市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "420000"
                },
                {
                    "id": "420900",
                    "parentId": "420000",
                    "title": "孝感市",
                    "value": "孝感市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "420000"
                },
                {
                    "id": "421000",
                    "parentId": "420000",
                    "title": "荆州市",
                    "value": "荆州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "420000"
                },
                {
                    "id": "421100",
                    "parentId": "420000",
                    "title": "黄冈市",
                    "value": "黄冈市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "420000"
                },
                {
                    "id": "421200",
                    "parentId": "420000",
                    "title": "咸宁市",
                    "value": "咸宁市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "420000"
                },
                {
                    "id": "421300",
                    "parentId": "420000",
                    "title": "随州市",
                    "value": "随州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "420000"
                },
                {
                    "id": "422800",
                    "parentId": "420000",
                    "title": "恩施土家族苗族自治州",
                    "value": "恩施土家族苗族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "420000"
                },
                {
                    "id": "429004",
                    "parentId": "420000",
                    "title": "仙桃市",
                    "value": "仙桃市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "420000"
                },
                {
                    "id": "429005",
                    "parentId": "420000",
                    "title": "潜江市",
                    "value": "潜江市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "420000"
                },
                {
                    "id": "429006",
                    "parentId": "420000",
                    "title": "天门市",
                    "value": "天门市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "420000"
                },
                {
                    "id": "429021",
                    "parentId": "420000",
                    "title": "神农架林区",
                    "value": "神农架林区",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "420000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "430000",
            "parentId": "0",
            "title": "湖南省",
            "value": "湖南省",
            "weight": 1,
            "children": [
                {
                    "id": "430100",
                    "parentId": "430000",
                    "title": "长沙市",
                    "value": "长沙市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "430000"
                },
                {
                    "id": "430200",
                    "parentId": "430000",
                    "title": "株洲市",
                    "value": "株洲市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "430000"
                },
                {
                    "id": "430300",
                    "parentId": "430000",
                    "title": "湘潭市",
                    "value": "湘潭市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "430000"
                },
                {
                    "id": "430400",
                    "parentId": "430000",
                    "title": "衡阳市",
                    "value": "衡阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "430000"
                },
                {
                    "id": "430500",
                    "parentId": "430000",
                    "title": "邵阳市",
                    "value": "邵阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "430000"
                },
                {
                    "id": "430600",
                    "parentId": "430000",
                    "title": "岳阳市",
                    "value": "岳阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "430000"
                },
                {
                    "id": "430700",
                    "parentId": "430000",
                    "title": "常德市",
                    "value": "常德市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "430000"
                },
                {
                    "id": "430800",
                    "parentId": "430000",
                    "title": "张家界市",
                    "value": "张家界市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "430000"
                },
                {
                    "id": "430900",
                    "parentId": "430000",
                    "title": "益阳市",
                    "value": "益阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "430000"
                },
                {
                    "id": "431000",
                    "parentId": "430000",
                    "title": "郴州市",
                    "value": "郴州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "430000"
                },
                {
                    "id": "431100",
                    "parentId": "430000",
                    "title": "永州市",
                    "value": "永州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "430000"
                },
                {
                    "id": "431200",
                    "parentId": "430000",
                    "title": "怀化市",
                    "value": "怀化市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "430000"
                },
                {
                    "id": "431300",
                    "parentId": "430000",
                    "title": "娄底市",
                    "value": "娄底市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "430000"
                },
                {
                    "id": "433100",
                    "parentId": "430000",
                    "title": "湘西土家族苗族自治州",
                    "value": "湘西土家族苗族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "430000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "440000",
            "parentId": "0",
            "title": "广东省",
            "value": "广东省",
            "weight": 1,
            "children": [
                {
                    "id": "440100",
                    "parentId": "440000",
                    "title": "广州市",
                    "value": "广州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "440200",
                    "parentId": "440000",
                    "title": "韶关市",
                    "value": "韶关市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "440300",
                    "parentId": "440000",
                    "title": "深圳市",
                    "value": "深圳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "440400",
                    "parentId": "440000",
                    "title": "珠海市",
                    "value": "珠海市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "440500",
                    "parentId": "440000",
                    "title": "汕头市",
                    "value": "汕头市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "440600",
                    "parentId": "440000",
                    "title": "佛山市",
                    "value": "佛山市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "440700",
                    "parentId": "440000",
                    "title": "江门市",
                    "value": "江门市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "440800",
                    "parentId": "440000",
                    "title": "湛江市",
                    "value": "湛江市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "440900",
                    "parentId": "440000",
                    "title": "茂名市",
                    "value": "茂名市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "441200",
                    "parentId": "440000",
                    "title": "肇庆市",
                    "value": "肇庆市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "441300",
                    "parentId": "440000",
                    "title": "惠州市",
                    "value": "惠州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "441400",
                    "parentId": "440000",
                    "title": "梅州市",
                    "value": "梅州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "441500",
                    "parentId": "440000",
                    "title": "汕尾市",
                    "value": "汕尾市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "441600",
                    "parentId": "440000",
                    "title": "河源市",
                    "value": "河源市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "441700",
                    "parentId": "440000",
                    "title": "阳江市",
                    "value": "阳江市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "441800",
                    "parentId": "440000",
                    "title": "清远市",
                    "value": "清远市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "441900",
                    "parentId": "440000",
                    "title": "东莞市",
                    "value": "东莞市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "442000",
                    "parentId": "440000",
                    "title": "中山市",
                    "value": "中山市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "442101",
                    "parentId": "440000",
                    "title": "东沙群岛",
                    "value": "东沙群岛",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "445100",
                    "parentId": "440000",
                    "title": "潮州市",
                    "value": "潮州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "445200",
                    "parentId": "440000",
                    "title": "揭阳市",
                    "value": "揭阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                },
                {
                    "id": "445300",
                    "parentId": "440000",
                    "title": "云浮市",
                    "value": "云浮市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "440000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "450000",
            "parentId": "0",
            "title": "广西壮族自治区",
            "value": "广西壮族自治区",
            "weight": 1,
            "children": [
                {
                    "id": "450100",
                    "parentId": "450000",
                    "title": "南宁市",
                    "value": "南宁市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "450000"
                },
                {
                    "id": "450200",
                    "parentId": "450000",
                    "title": "柳州市",
                    "value": "柳州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "450000"
                },
                {
                    "id": "450300",
                    "parentId": "450000",
                    "title": "桂林市",
                    "value": "桂林市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "450000"
                },
                {
                    "id": "450400",
                    "parentId": "450000",
                    "title": "梧州市",
                    "value": "梧州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "450000"
                },
                {
                    "id": "450500",
                    "parentId": "450000",
                    "title": "北海市",
                    "value": "北海市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "450000"
                },
                {
                    "id": "450600",
                    "parentId": "450000",
                    "title": "防城港市",
                    "value": "防城港市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "450000"
                },
                {
                    "id": "450700",
                    "parentId": "450000",
                    "title": "钦州市",
                    "value": "钦州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "450000"
                },
                {
                    "id": "450800",
                    "parentId": "450000",
                    "title": "贵港市",
                    "value": "贵港市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "450000"
                },
                {
                    "id": "450900",
                    "parentId": "450000",
                    "title": "玉林市",
                    "value": "玉林市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "450000"
                },
                {
                    "id": "451000",
                    "parentId": "450000",
                    "title": "百色市",
                    "value": "百色市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "450000"
                },
                {
                    "id": "451100",
                    "parentId": "450000",
                    "title": "贺州市",
                    "value": "贺州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "450000"
                },
                {
                    "id": "451200",
                    "parentId": "450000",
                    "title": "河池市",
                    "value": "河池市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "450000"
                },
                {
                    "id": "451300",
                    "parentId": "450000",
                    "title": "来宾市",
                    "value": "来宾市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "450000"
                },
                {
                    "id": "451400",
                    "parentId": "450000",
                    "title": "崇左市",
                    "value": "崇左市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "450000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "460000",
            "parentId": "0",
            "title": "海南省",
            "value": "海南省",
            "weight": 1,
            "children": [
                {
                    "id": "460100",
                    "parentId": "460000",
                    "title": "海口市",
                    "value": "海口市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "460000"
                },
                {
                    "id": "460200",
                    "parentId": "460000",
                    "title": "三亚市",
                    "value": "三亚市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "460000"
                },
                {
                    "id": "460300",
                    "parentId": "460000",
                    "title": "三沙市",
                    "value": "三沙市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "460000"
                },
                {
                    "id": "469001",
                    "parentId": "460000",
                    "title": "五指山市",
                    "value": "五指山市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "460000"
                },
                {
                    "id": "469002",
                    "parentId": "460000",
                    "title": "琼海市",
                    "value": "琼海市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "460000"
                },
                {
                    "id": "469003",
                    "parentId": "460000",
                    "title": "儋州市",
                    "value": "儋州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "460000"
                },
                {
                    "id": "469005",
                    "parentId": "460000",
                    "title": "文昌市",
                    "value": "文昌市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "460000"
                },
                {
                    "id": "469006",
                    "parentId": "460000",
                    "title": "万宁市",
                    "value": "万宁市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "460000"
                },
                {
                    "id": "469007",
                    "parentId": "460000",
                    "title": "东方市",
                    "value": "东方市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "460000"
                },
                {
                    "id": "469025",
                    "parentId": "460000",
                    "title": "定安县",
                    "value": "定安县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "460000"
                },
                {
                    "id": "469026",
                    "parentId": "460000",
                    "title": "屯昌县",
                    "value": "屯昌县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "460000"
                },
                {
                    "id": "469027",
                    "parentId": "460000",
                    "title": "澄迈县",
                    "value": "澄迈县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "460000"
                },
                {
                    "id": "469028",
                    "parentId": "460000",
                    "title": "临高县",
                    "value": "临高县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "460000"
                },
                {
                    "id": "469030",
                    "parentId": "460000",
                    "title": "白沙黎族自治县",
                    "value": "白沙黎族自治县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "460000"
                },
                {
                    "id": "469031",
                    "parentId": "460000",
                    "title": "昌江黎族自治县",
                    "value": "昌江黎族自治县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "460000"
                },
                {
                    "id": "469033",
                    "parentId": "460000",
                    "title": "乐东黎族自治县",
                    "value": "乐东黎族自治县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "460000"
                },
                {
                    "id": "469034",
                    "parentId": "460000",
                    "title": "陵水黎族自治县",
                    "value": "陵水黎族自治县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "460000"
                },
                {
                    "id": "469035",
                    "parentId": "460000",
                    "title": "保亭黎族苗族自治县",
                    "value": "保亭黎族苗族自治县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "460000"
                },
                {
                    "id": "469036",
                    "parentId": "460000",
                    "title": "琼中黎族苗族自治县",
                    "value": "琼中黎族苗族自治县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "460000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "500000",
            "parentId": "0",
            "title": "重庆",
            "value": "重庆",
            "weight": 1,
            "children": [
                {
                    "id": "500100",
                    "parentId": "500000",
                    "title": "重庆市",
                    "value": "重庆市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "500000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "510000",
            "parentId": "0",
            "title": "四川省",
            "value": "四川省",
            "weight": 1,
            "children": [
                {
                    "id": "510100",
                    "parentId": "510000",
                    "title": "成都市",
                    "value": "成都市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "510300",
                    "parentId": "510000",
                    "title": "自贡市",
                    "value": "自贡市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "510400",
                    "parentId": "510000",
                    "title": "攀枝花市",
                    "value": "攀枝花市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "510500",
                    "parentId": "510000",
                    "title": "泸州市",
                    "value": "泸州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "510600",
                    "parentId": "510000",
                    "title": "德阳市",
                    "value": "德阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "510700",
                    "parentId": "510000",
                    "title": "绵阳市",
                    "value": "绵阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "510800",
                    "parentId": "510000",
                    "title": "广元市",
                    "value": "广元市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "510900",
                    "parentId": "510000",
                    "title": "遂宁市",
                    "value": "遂宁市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "511000",
                    "parentId": "510000",
                    "title": "内江市",
                    "value": "内江市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "511100",
                    "parentId": "510000",
                    "title": "乐山市",
                    "value": "乐山市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "511300",
                    "parentId": "510000",
                    "title": "南充市",
                    "value": "南充市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "511400",
                    "parentId": "510000",
                    "title": "眉山市",
                    "value": "眉山市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "511500",
                    "parentId": "510000",
                    "title": "宜宾市",
                    "value": "宜宾市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "511600",
                    "parentId": "510000",
                    "title": "广安市",
                    "value": "广安市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "511700",
                    "parentId": "510000",
                    "title": "达州市",
                    "value": "达州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "511800",
                    "parentId": "510000",
                    "title": "雅安市",
                    "value": "雅安市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "511900",
                    "parentId": "510000",
                    "title": "巴中市",
                    "value": "巴中市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "512000",
                    "parentId": "510000",
                    "title": "资阳市",
                    "value": "资阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "513200",
                    "parentId": "510000",
                    "title": "阿坝藏族羌族自治州",
                    "value": "阿坝藏族羌族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "513300",
                    "parentId": "510000",
                    "title": "甘孜藏族自治州",
                    "value": "甘孜藏族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                },
                {
                    "id": "513400",
                    "parentId": "510000",
                    "title": "凉山彝族自治州",
                    "value": "凉山彝族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "510000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "520000",
            "parentId": "0",
            "title": "贵州省",
            "value": "贵州省",
            "weight": 1,
            "children": [
                {
                    "id": "520100",
                    "parentId": "520000",
                    "title": "贵阳市",
                    "value": "贵阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "520000"
                },
                {
                    "id": "520200",
                    "parentId": "520000",
                    "title": "六盘水市",
                    "value": "六盘水市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "520000"
                },
                {
                    "id": "520300",
                    "parentId": "520000",
                    "title": "遵义市",
                    "value": "遵义市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "520000"
                },
                {
                    "id": "520400",
                    "parentId": "520000",
                    "title": "安顺市",
                    "value": "安顺市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "520000"
                },
                {
                    "id": "522200",
                    "parentId": "520000",
                    "title": "铜仁市",
                    "value": "铜仁市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "520000"
                },
                {
                    "id": "522300",
                    "parentId": "520000",
                    "title": "黔西南布依族苗族自治州",
                    "value": "黔西南布依族苗族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "520000"
                },
                {
                    "id": "522400",
                    "parentId": "520000",
                    "title": "毕节市",
                    "value": "毕节市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "520000"
                },
                {
                    "id": "522600",
                    "parentId": "520000",
                    "title": "黔东南苗族侗族自治州",
                    "value": "黔东南苗族侗族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "520000"
                },
                {
                    "id": "522700",
                    "parentId": "520000",
                    "title": "黔南布依族苗族自治州",
                    "value": "黔南布依族苗族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "520000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "530000",
            "parentId": "0",
            "title": "云南省",
            "value": "云南省",
            "weight": 1,
            "children": [
                {
                    "id": "530100",
                    "parentId": "530000",
                    "title": "昆明市",
                    "value": "昆明市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "530000"
                },
                {
                    "id": "530300",
                    "parentId": "530000",
                    "title": "曲靖市",
                    "value": "曲靖市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "530000"
                },
                {
                    "id": "530400",
                    "parentId": "530000",
                    "title": "玉溪市",
                    "value": "玉溪市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "530000"
                },
                {
                    "id": "530500",
                    "parentId": "530000",
                    "title": "保山市",
                    "value": "保山市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "530000"
                },
                {
                    "id": "530600",
                    "parentId": "530000",
                    "title": "昭通市",
                    "value": "昭通市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "530000"
                },
                {
                    "id": "530700",
                    "parentId": "530000",
                    "title": "丽江市",
                    "value": "丽江市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "530000"
                },
                {
                    "id": "530800",
                    "parentId": "530000",
                    "title": "普洱市",
                    "value": "普洱市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "530000"
                },
                {
                    "id": "530900",
                    "parentId": "530000",
                    "title": "临沧市",
                    "value": "临沧市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "530000"
                },
                {
                    "id": "532300",
                    "parentId": "530000",
                    "title": "楚雄彝族自治州",
                    "value": "楚雄彝族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "530000"
                },
                {
                    "id": "532500",
                    "parentId": "530000",
                    "title": "红河哈尼族彝族自治州",
                    "value": "红河哈尼族彝族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "530000"
                },
                {
                    "id": "532600",
                    "parentId": "530000",
                    "title": "文山壮族苗族自治州",
                    "value": "文山壮族苗族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "530000"
                },
                {
                    "id": "532800",
                    "parentId": "530000",
                    "title": "西双版纳傣族自治州",
                    "value": "西双版纳傣族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "530000"
                },
                {
                    "id": "532900",
                    "parentId": "530000",
                    "title": "大理白族自治州",
                    "value": "大理白族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "530000"
                },
                {
                    "id": "533100",
                    "parentId": "530000",
                    "title": "德宏傣族景颇族自治州",
                    "value": "德宏傣族景颇族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "530000"
                },
                {
                    "id": "533300",
                    "parentId": "530000",
                    "title": "怒江傈僳族自治州",
                    "value": "怒江傈僳族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "530000"
                },
                {
                    "id": "533400",
                    "parentId": "530000",
                    "title": "迪庆藏族自治州",
                    "value": "迪庆藏族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "530000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "540000",
            "parentId": "0",
            "title": "西藏自治区",
            "value": "西藏自治区",
            "weight": 1,
            "children": [
                {
                    "id": "540100",
                    "parentId": "540000",
                    "title": "拉萨市",
                    "value": "拉萨市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "540000"
                },
                {
                    "id": "542100",
                    "parentId": "540000",
                    "title": "昌都地区",
                    "value": "昌都地区",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "540000"
                },
                {
                    "id": "542200",
                    "parentId": "540000",
                    "title": "山南地区",
                    "value": "山南地区",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "540000"
                },
                {
                    "id": "542300",
                    "parentId": "540000",
                    "title": "日喀则地区",
                    "value": "日喀则地区",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "540000"
                },
                {
                    "id": "542400",
                    "parentId": "540000",
                    "title": "那曲地区",
                    "value": "那曲地区",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "540000"
                },
                {
                    "id": "542500",
                    "parentId": "540000",
                    "title": "阿里地区",
                    "value": "阿里地区",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "540000"
                },
                {
                    "id": "542600",
                    "parentId": "540000",
                    "title": "林芝地区",
                    "value": "林芝地区",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "540000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "610000",
            "parentId": "0",
            "title": "陕西省",
            "value": "陕西省",
            "weight": 1,
            "children": [
                {
                    "id": "610100",
                    "parentId": "610000",
                    "title": "西安市",
                    "value": "西安市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "610000"
                },
                {
                    "id": "610200",
                    "parentId": "610000",
                    "title": "铜川市",
                    "value": "铜川市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "610000"
                },
                {
                    "id": "610300",
                    "parentId": "610000",
                    "title": "宝鸡市",
                    "value": "宝鸡市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "610000"
                },
                {
                    "id": "610400",
                    "parentId": "610000",
                    "title": "咸阳市",
                    "value": "咸阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "610000"
                },
                {
                    "id": "610500",
                    "parentId": "610000",
                    "title": "渭南市",
                    "value": "渭南市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "610000"
                },
                {
                    "id": "610600",
                    "parentId": "610000",
                    "title": "延安市",
                    "value": "延安市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "610000"
                },
                {
                    "id": "610700",
                    "parentId": "610000",
                    "title": "汉中市",
                    "value": "汉中市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "610000"
                },
                {
                    "id": "610800",
                    "parentId": "610000",
                    "title": "榆林市",
                    "value": "榆林市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "610000"
                },
                {
                    "id": "610900",
                    "parentId": "610000",
                    "title": "安康市",
                    "value": "安康市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "610000"
                },
                {
                    "id": "611000",
                    "parentId": "610000",
                    "title": "商洛市",
                    "value": "商洛市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "610000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "620000",
            "parentId": "0",
            "title": "甘肃省",
            "value": "甘肃省",
            "weight": 1,
            "children": [
                {
                    "id": "620100",
                    "parentId": "620000",
                    "title": "兰州市",
                    "value": "兰州市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "620000"
                },
                {
                    "id": "620200",
                    "parentId": "620000",
                    "title": "嘉峪关市",
                    "value": "嘉峪关市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "620000"
                },
                {
                    "id": "620300",
                    "parentId": "620000",
                    "title": "金昌市",
                    "value": "金昌市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "620000"
                },
                {
                    "id": "620400",
                    "parentId": "620000",
                    "title": "白银市",
                    "value": "白银市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "620000"
                },
                {
                    "id": "620500",
                    "parentId": "620000",
                    "title": "天水市",
                    "value": "天水市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "620000"
                },
                {
                    "id": "620600",
                    "parentId": "620000",
                    "title": "武威市",
                    "value": "武威市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "620000"
                },
                {
                    "id": "620700",
                    "parentId": "620000",
                    "title": "张掖市",
                    "value": "张掖市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "620000"
                },
                {
                    "id": "620800",
                    "parentId": "620000",
                    "title": "平凉市",
                    "value": "平凉市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "620000"
                },
                {
                    "id": "620900",
                    "parentId": "620000",
                    "title": "酒泉市",
                    "value": "酒泉市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "620000"
                },
                {
                    "id": "621000",
                    "parentId": "620000",
                    "title": "庆阳市",
                    "value": "庆阳市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "620000"
                },
                {
                    "id": "621100",
                    "parentId": "620000",
                    "title": "定西市",
                    "value": "定西市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "620000"
                },
                {
                    "id": "621200",
                    "parentId": "620000",
                    "title": "陇南市",
                    "value": "陇南市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "620000"
                },
                {
                    "id": "622900",
                    "parentId": "620000",
                    "title": "临夏回族自治州",
                    "value": "临夏回族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "620000"
                },
                {
                    "id": "623000",
                    "parentId": "620000",
                    "title": "甘南藏族自治州",
                    "value": "甘南藏族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "620000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "630000",
            "parentId": "0",
            "title": "青海省",
            "value": "青海省",
            "weight": 1,
            "children": [
                {
                    "id": "630100",
                    "parentId": "630000",
                    "title": "西宁市",
                    "value": "西宁市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "630000"
                },
                {
                    "id": "632100",
                    "parentId": "630000",
                    "title": "海东市",
                    "value": "海东市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "630000"
                },
                {
                    "id": "632200",
                    "parentId": "630000",
                    "title": "海北藏族自治州",
                    "value": "海北藏族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "630000"
                },
                {
                    "id": "632300",
                    "parentId": "630000",
                    "title": "黄南藏族自治州",
                    "value": "黄南藏族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "630000"
                },
                {
                    "id": "632500",
                    "parentId": "630000",
                    "title": "海南藏族自治州",
                    "value": "海南藏族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "630000"
                },
                {
                    "id": "632600",
                    "parentId": "630000",
                    "title": "果洛藏族自治州",
                    "value": "果洛藏族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "630000"
                },
                {
                    "id": "632700",
                    "parentId": "630000",
                    "title": "玉树藏族自治州",
                    "value": "玉树藏族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "630000"
                },
                {
                    "id": "632800",
                    "parentId": "630000",
                    "title": "海西蒙古族藏族自治州",
                    "value": "海西蒙古族藏族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "630000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "640000",
            "parentId": "0",
            "title": "宁夏回族自治区",
            "value": "宁夏回族自治区",
            "weight": 1,
            "children": [
                {
                    "id": "640100",
                    "parentId": "640000",
                    "title": "银川市",
                    "value": "银川市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "640000"
                },
                {
                    "id": "640200",
                    "parentId": "640000",
                    "title": "石嘴山市",
                    "value": "石嘴山市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "640000"
                },
                {
                    "id": "640300",
                    "parentId": "640000",
                    "title": "吴忠市",
                    "value": "吴忠市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "640000"
                },
                {
                    "id": "640400",
                    "parentId": "640000",
                    "title": "固原市",
                    "value": "固原市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "640000"
                },
                {
                    "id": "640500",
                    "parentId": "640000",
                    "title": "中卫市",
                    "value": "中卫市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "640000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "650000",
            "parentId": "0",
            "title": "新疆维吾尔自治区",
            "value": "新疆维吾尔自治区",
            "weight": 1,
            "children": [
                {
                    "id": "650100",
                    "parentId": "650000",
                    "title": "乌鲁木齐市",
                    "value": "乌鲁木齐市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "650000"
                },
                {
                    "id": "650200",
                    "parentId": "650000",
                    "title": "克拉玛依市",
                    "value": "克拉玛依市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "650000"
                },
                {
                    "id": "652100",
                    "parentId": "650000",
                    "title": "吐鲁番地区",
                    "value": "吐鲁番地区",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "650000"
                },
                {
                    "id": "652200",
                    "parentId": "650000",
                    "title": "哈密地区",
                    "value": "哈密地区",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "650000"
                },
                {
                    "id": "652300",
                    "parentId": "650000",
                    "title": "昌吉回族自治州",
                    "value": "昌吉回族自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "650000"
                },
                {
                    "id": "652700",
                    "parentId": "650000",
                    "title": "博尔塔拉蒙古自治州",
                    "value": "博尔塔拉蒙古自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "650000"
                },
                {
                    "id": "652800",
                    "parentId": "650000",
                    "title": "巴音郭楞蒙古自治州",
                    "value": "巴音郭楞蒙古自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "650000"
                },
                {
                    "id": "652900",
                    "parentId": "650000",
                    "title": "阿克苏地区",
                    "value": "阿克苏地区",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "650000"
                },
                {
                    "id": "653000",
                    "parentId": "650000",
                    "title": "克孜勒苏柯尔克孜自治州",
                    "value": "克孜勒苏柯尔克孜自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "650000"
                },
                {
                    "id": "653100",
                    "parentId": "650000",
                    "title": "喀什地区",
                    "value": "喀什地区",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "650000"
                },
                {
                    "id": "653200",
                    "parentId": "650000",
                    "title": "和田地区",
                    "value": "和田地区",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "650000"
                },
                {
                    "id": "654000",
                    "parentId": "650000",
                    "title": "伊犁哈萨克自治州",
                    "value": "伊犁哈萨克自治州",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "650000"
                },
                {
                    "id": "654200",
                    "parentId": "650000",
                    "title": "塔城地区",
                    "value": "塔城地区",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "650000"
                },
                {
                    "id": "654300",
                    "parentId": "650000",
                    "title": "阿勒泰地区",
                    "value": "阿勒泰地区",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "650000"
                },
                {
                    "id": "659001",
                    "parentId": "650000",
                    "title": "石河子市",
                    "value": "石河子市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "650000"
                },
                {
                    "id": "659002",
                    "parentId": "650000",
                    "title": "阿拉尔市",
                    "value": "阿拉尔市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "650000"
                },
                {
                    "id": "659003",
                    "parentId": "650000",
                    "title": "图木舒克市",
                    "value": "图木舒克市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "650000"
                },
                {
                    "id": "659004",
                    "parentId": "650000",
                    "title": "五家渠市",
                    "value": "五家渠市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "650000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "710000",
            "parentId": "0",
            "title": "台湾",
            "value": "台湾",
            "weight": 1,
            "children": [
                {
                    "id": "710100",
                    "parentId": "710000",
                    "title": "台北市",
                    "value": "台北市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "710200",
                    "parentId": "710000",
                    "title": "高雄市",
                    "value": "高雄市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "710300",
                    "parentId": "710000",
                    "title": "台南市",
                    "value": "台南市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "710400",
                    "parentId": "710000",
                    "title": "台中市",
                    "value": "台中市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "710500",
                    "parentId": "710000",
                    "title": "金门县",
                    "value": "金门县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "710600",
                    "parentId": "710000",
                    "title": "南投县",
                    "value": "南投县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "710700",
                    "parentId": "710000",
                    "title": "基隆市",
                    "value": "基隆市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "710800",
                    "parentId": "710000",
                    "title": "新竹市",
                    "value": "新竹市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "710900",
                    "parentId": "710000",
                    "title": "嘉义市",
                    "value": "嘉义市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "711100",
                    "parentId": "710000",
                    "title": "新北市",
                    "value": "新北市",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "711200",
                    "parentId": "710000",
                    "title": "宜兰县",
                    "value": "宜兰县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "711300",
                    "parentId": "710000",
                    "title": "新竹县",
                    "value": "新竹县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "711400",
                    "parentId": "710000",
                    "title": "桃园县",
                    "value": "桃园县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "711500",
                    "parentId": "710000",
                    "title": "苗栗县",
                    "value": "苗栗县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "711700",
                    "parentId": "710000",
                    "title": "彰化县",
                    "value": "彰化县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "711900",
                    "parentId": "710000",
                    "title": "嘉义县",
                    "value": "嘉义县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "712100",
                    "parentId": "710000",
                    "title": "云林县",
                    "value": "云林县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "712400",
                    "parentId": "710000",
                    "title": "屏东县",
                    "value": "屏东县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "712500",
                    "parentId": "710000",
                    "title": "台东县",
                    "value": "台东县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "712600",
                    "parentId": "710000",
                    "title": "花莲县",
                    "value": "花莲县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "712700",
                    "parentId": "710000",
                    "title": "澎湖县",
                    "value": "澎湖县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                },
                {
                    "id": "712800",
                    "parentId": "710000",
                    "title": "连江县",
                    "value": "连江县",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "710000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "810000",
            "parentId": "0",
            "title": "香港特别行政区",
            "value": "香港特别行政区",
            "weight": 1,
            "children": [
                {
                    "id": "810100",
                    "parentId": "810000",
                    "title": "香港岛",
                    "value": "香港岛",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "810000"
                },
                {
                    "id": "810200",
                    "parentId": "810000",
                    "title": "九龙",
                    "value": "九龙",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "810000"
                },
                {
                    "id": "810300",
                    "parentId": "810000",
                    "title": "新界",
                    "value": "新界",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "810000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        },
        {
            "id": "820000",
            "parentId": "0",
            "title": "澳门特别行政区",
            "value": "澳门特别行政区",
            "weight": 1,
            "children": [
                {
                    "id": "820100",
                    "parentId": "820000",
                    "title": "澳门半岛",
                    "value": "澳门半岛",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "820000"
                },
                {
                    "id": "820200",
                    "parentId": "820000",
                    "title": "离岛",
                    "value": "离岛",
                    "weight": 1,
                    "children": [],
                    "alias": "city",
                    "icon": null,
                    "image": null,
                    "type": null,
                    "linkAddress": null,
                    "pid": "820000"
                }
            ],
            "alias": "provincial",
            "icon": null,
            "image": null,
            "type": null,
            "linkAddress": null,
            "pid": "0"
        }
    ]
	
	export default provincialCity