<template>
  <div class="main">
    <Title></Title>
    <img
      src="@/assets/img/bgc2.png"
      style="width: 100%; height: 220px; margin-top: 80px; object-fit: cover"
      alt=""
    />
    <div class="content c">
      <div class="stepsBox">
        <!-- <el-steps :active="stepsActive" finish-status="success">
          <el-step title="基本信息"></el-step>
          <el-step title="等待审核"></el-step>
        </el-steps> -->
        <div style="width: 450px; display: flex">
          <div class="step1" :class="stepsActive == 0 ? 'stepsActive' : ''">
            <span v-if="stepsActive == 0">1</span>
            <span v-else>√</span>
            <span>基本信息</span>
          </div>
          <el-divider></el-divider>
          <div class="step2" :class="stepsActive == 1 ? 'stepsActive' : ''">
            <span>2</span>
            <span>等待审核</span>
          </div>
        </div>
      </div>
      <div class="stepOne" v-if="stepsActive == 0">
        <div class="baseInfo">
          <span></span>
          <span>基本信息</span>
        </div>
        <el-form
          class="form"
          ref="form"
          :model="form"
          label-width="150px"
          :rules="formRules"
        >
          <div class="myInfo">
            <div class="myInfoLeft">
              <el-form-item label="姓名：" prop="name">
                <el-input v-model="form.name" placeholder="请输入性名"></el-input>
              </el-form-item>
              <el-form-item label="性别：" prop="sex">
                <!-- <el-input v-model="form.sex"></el-input> -->
                <el-select v-model="form.sex" placeholder="请选择性别">
                  <el-option
                    v-for="item in sex"
                    :key="item.name"
                    :label="item.name"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="所在区域：" prop="area">
                <el-cascader
                  v-model="form.area"
                  :show-all-levels="false"
                  placeholder="请选择城市"
                  :props="cityProps"
                  @change="handleChangeCity"
                ></el-cascader>
              </el-form-item> -->
              <el-form-item label="所在区域：" prop="area">
                <el-cascader
                  v-model="form.area"
                  :show-all-levels="false"
                  placeholder="请选择城市"
                  :props="cityProps"
                  :options="provincialCity"
                  @change="handleChangeCity"
                ></el-cascader>
              </el-form-item>
            <el-form-item label="职位类别：" prop="positionType">
              <el-radio-group v-model="form.positionType">
                <el-radio label="1">技术类</el-radio>
                <el-radio label="2">管理类</el-radio>
              </el-radio-group>
            </el-form-item>
              <el-form-item label="身份证号：" prop="identityCode">
                <el-input v-model="form.identityCode"></el-input>
              </el-form-item>
            </div>
          </div>
          <el-form-item label="身份证正面照：" prop="identityCard1">
            <el-upload
              drag
              name="uploadfile"
              :action="$global.uploadAction"
              :before-upload="beforeUpload"
              :on-success="upSuccess1"
              :on-error="upError"
              :on-remove="upRemve"
              :on-change="upChange"
              :show-file-list="false"
              multiple
              class="uploadBox"
            >
              <img
                v-if="form.identityCard1"
                :src="form.identityCard1"
                alt=""
              />
              <img v-else src="http://121.36.46.11:9000/job-app/56932_1.png" alt="" />
              <div class="el-upload__tip" slot="tip">
                <span>请上传身份证正面带有头像的清晰图片</span>
                <span>大小:≤5M</span>
                <!-- <span>格式为：.rar .zip .doc .docx .pdf .jpg...</span> -->
                <span>格式为：.jpg .png</span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="身份证反面照：" prop="identityCard0">
            <el-upload
              drag
              name="uploadfile"
              :action="$global.uploadAction"
              :before-upload="beforeUpload"
              :on-success="upSuccess2"
              :on-error="upError"
              :on-remove="upRemve"
              :on-change="upChange"
              :show-file-list="false"
              multiple
              class="uploadBox"
            >
              <img
                v-if="form.identityCard0"
                :src="form.identityCard0"
                alt=""
              />
              <img v-else src="http://121.36.46.11:9000/job-app/56932_2.png" alt="" />
              <div class="el-upload__tip" slot="tip">
                <span>请上传身份证反面带有国徽的清晰图片</span>
                <span>大小:≤5M</span>
                <!-- <span>格式为：.rar .zip .doc .docx .pdf .jpg...</span> -->
                <span>格式为：.jpg .png</span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="学历证书：" prop="degreeCertificate">
            <el-upload
              drag
              name="uploadfile"
              :action="$global.uploadAction"
              :before-upload="beforeUpload"
              :on-success="upSuccess3"
              :on-error="upError"
              :on-remove="upRemve"
              :on-change="upChange"
              :show-file-list="false"
              multiple
              class="uploadBox"
            >
              <img
                v-if="form.degreeCertificate"
                :src="form.degreeCertificate"
                alt=""
              />
              <img v-else src="http://121.36.46.11:9000/job-app/56932_3.png" alt="" />
              <div class="el-upload__tip" slot="tip">
                <span>请上传学历证书的清晰图片</span>
                <span>大小:≤5M</span>
                <!-- <span>格式为：.rar .zip .doc .docx .pdf .jpg...</span> -->
                <span>格式为：.jpg .png</span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="资格证书：" prop="qualificationCertificate">
            <el-upload
              drag
              name="uploadfile"
              :action="$global.uploadAction"
              :before-upload="beforeUpload"
              :on-success="upSuccess4"
              :on-error="upError"
              :on-remove="upRemve"
              :on-change="upChange"
              :show-file-list="false"
              multiple
              class="uploadBox"
            >
              <img
                v-if="form.qualificationCertificate"
                :src="form.qualificationCertificate"
                alt=""
              />
              <img v-else src="http://121.36.46.11:9000/job-app/56932_5.png" alt="" />
              <div class="el-upload__tip" slot="tip">
                <span>请上传资格证书的清晰图片</span>
                <span>大小:≤5M</span>
                <!-- <span>格式为：.rar .zip .doc .docx .pdf .jpg...</span> -->
                <span>格式为：.jpg .png</span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="职称证书：" prop="titleCertificate">
            <!-- <div class="imgs">
              <img
                v-if="form.titleCertificate"
                :src="$global.baseUrl + imgPath + form.titleCertificate"
                alt=""
              />
            </div> -->
            <el-upload
              drag
              name="uploadfile"
              :action="$global.uploadAction"
              :before-upload="beforeUpload"
              :on-success="upSuccess5"
              :on-error="upError"
              :on-remove="upRemve"
              :on-change="upChange"
              :show-file-list="false"
              multiple
              class="uploadBox"
            >
              <img
                v-if="form.titleCertificate"
                :src="form.titleCertificate"
                alt=""
              />
              <img v-else src="http://121.36.46.11:9000/job-app/56932_6.png" alt="" />
              <div class="el-upload__tip" slot="tip">
                <span>请上传职称证书的清晰图片</span>
                <span>大小:≤5M</span>
                <span>格式为：.jpg .png</span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="工作证明：" prop="pow">
            <el-upload
              drag
              name="uploadfile"
              :action="$global.uploadAction"
              :before-upload="beforeUpload"
              :on-success="upSuccess6"
              :on-error="upError"
              :on-remove="upRemve"
              :on-change="upChange"
              :show-file-list="false"
              multiple
              class="uploadBox"
            >
              <img
                v-if="form.pow"
                :src="form.pow"
                alt=""
              />
              <img v-else src="http://121.36.46.11:9000/job-app/56932_4.png" alt="" />
              <div class="el-upload__tip" slot="tip">
                <span>请上传工作证明的清晰图片</span>
                <span>大小:≤5M</span>
                <!-- .rar .zip .doc .docx .pdf -->
                <span>格式为：.jpg .png</span>
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
        <div class="btnBox">
          <el-button type="primary" :loading="saveFlag" @click="next"
            >下一步</el-button
          >
        </div>
      </div>
      <div class="stepTwo" v-if="stepsActive == 1">
        <span v-if="certificates.audit_status == 2">审核已通过</span>
        <span v-else>您的资料已经提交完成，请耐心等待平台审核</span>
        <img src="@/assets/img/wait.png" alt="" />
      </div>
    </div>
    <Footer style="margin-top: 100px"></Footer>
  </div>
</template>

<script>
import Title from '@/components/hometitle/hometitle.vue'
import Footer from '@/components/foot/foot.vue'
import { getProvincial, getCity } from '@/api/index/index'
import { getJobUserAuth } from '@/api/jobInformation/jobInformation'
import { addJobUserAuth } from '@/api/authen/authen'
import provincialCity from '@/assets/js/provincialCity.js'
export default {
  name: '',
  data() {
    const identityCode = (rule, value, callback) => {
      let regTest =
        /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      if (!value) {
        callback(new Error('请输入身份证号'))
      } else {
        if (!regTest.test(value)) {
          callback(new Error('身份证格式不正确'))
          return
        }
        callback()
      }
    }
    return {
      provincialCity: provincialCity,
      saveFlag: false,
      stepsActive: 0, //步骤条
      dict: [],
      sex: [],
      // cityProps: {},
      cityProps: {
        value: 'id',
        label: 'value',
        children: 'children',
      },
      form: {
        name: '', //姓名
        sex: '', //性别
        area: '', //城市
        identityCode: '', //身份证号
        identityCard1: '', //身份证正面
        identityCard0: '', //身份证背面
        degreeCertificate: '', //学历证书
        qualificationCertificate: '', //资格证书
        titleCertificate: '', //职称证书
        pow: '', //工作证明
        jobUserId: '', //用户id
        positionType: '1' // 个人职位类别(用于个人评价区分)
      },
      formRules: {
        name: [{ required: true, message: '请输入真实姓名' }],
        sex: [{ required: true, message: '请选择性别' }],
        area: [{ required: true, message: '请选择城市' }],
        identityCode: [{ required: true, validator: identityCode }],
        identityCard1: [{ required: true, message: '请上传身份证正面附件' }],
        identityCard0: [{ required: true, message: '请上传身份证反面附件' }],
      },
      certificates: {}, //认证信息
      cityLit: [],
      chooseCity: false,
    }
  },
  watch: {},
  props: {},
  components: {
    Title,
    Footer,
  },
  computed: {},
  created() {
    // this.cityProps = {
    //   value: 'PROVINCE_CODE',
    //   label: 'SHORT_NAME',
    //   children: 'children',
    //   lazy: true,
    //   lazyLoad: (node, resolve) => {
    //     const { level } = node
    //     if (level == 0) {
    //       getProvincial().then(res => {
    //         const city = res.data
    //         resolve(city)
    //       })
    //     }
    //     if (level == 1) {
    //       getCity({ PROVINCE_CODE: node.value }).then(res => {
    //         let arr = []
    //         res.data.forEach(i => {
    //           const obj = {
    //             PROVINCE_CODE: i.CITY_CODE,
    //             SHORT_NAME: i.SHORT_NAME,
    //             leaf: true,
    //           }
    //           arr.push(obj)
    //         })
    //         const city = arr
    //         resolve(city)
    //       })
    //     }
    //   },
    // }
    this.dict = JSON.parse(localStorage.getItem('dict'))
    this.sex = this.dict.filter(i => i.code == 'sex')[0].children
    this.cityLit = JSON.parse(localStorage.getItem('cityList'))
    // this.sex.splice(this.sex.length - 1, 1)
    this.getJobUserAuthForId()
  },
  mounted() {
    this.childrenNull(this.provincialCity)
  },
  methods: {
    childrenNull(list) {
      if (list && list.length > 0) {
        list.forEach(i => {
          if (i.children && i.children.length == 0) {
            i.children = null
          } else {
            this.childrenNull(i.children)
          }
        })
      }
    },
    // 查询认证信息
    getJobUserAuthForId() {
      getJobUserAuth().then(res => {
        console.log(res.data)
        if (res.data) {
          this.certificates = res.data
          if (
            res.data.audit_status == 2 ||
            res.data.audit_status == 1 ||
            res.data.audit_status == 4
          ) {
            console.log(res.data)
            this.stepsActive = 1
            return
          }
          //  else if(res.data.audit_status == 1) {
          //   this.stepsActive = 0
          // }
          if (res.data.audit_status == 3) {
            this.form.auditStatus = 4
          }
          this.form.id = this.certificates.id
          this.form.name = this.certificates.name
          this.form.sex = this.certificates.sex
          this.form.area = this.certificates.area
          // let area = this.cityLit.filter(
          //   i => i.CITY_CODE == this.certificates.area
          // )[0]
          // this.form.area = [area.PROVINCE_CODE, area.CITY_CODE]
          console.log(this.form.area)

          // this.form.area = JSON.parse(this.certificates.area)
          this.form.identityCode = this.certificates.identity_code
          this.form.identityCard1 = this.certificates.identity_card1 ? this.certificates.identity_card1 : ''
          this.form.identityCard0 = this.certificates.identity_card0 ? this.certificates.identity_card0 : ''
          this.form.degreeCertificate = this.certificates.degree_certificate ? this.certificates.degree_certificate : ''
          this.form.qualificationCertificate =
            this.certificates.qualification_certificate ? this.certificates.qualification_certificate : ''
          this.form.titleCertificate = this.certificates.title_certificate ? this.certificates.title_certificate : ''
          this.form.pow = this.certificates.pow ? this.certificates.pow : ''
          this.form.positionType = this.certificates.position_type ? this.certificates.position_type : '1'
          this.form.jobUserId = this.certificates.job_user_id
          console.log(this.form)
        }
      })
    },
    handleChangeCity(val) {
      console.log(val)
      this.chooseCity = true
      // this.form.area = val[val.length - 1]
      // this.form.area = val
    },
    // 身份证正面上传
    upSuccess1(res) {
      this.form.identityCard1 = res.msg
    },
    // 身份背正面上传
    upSuccess2(res) {
      this.form.identityCard0 = res.msg
    },
    // 学历证书上传
    upSuccess3(res) {
      this.form.degreeCertificate = res.msg
    },
    // 资格证书上传
    upSuccess4(res) {
      this.form.qualificationCertificate = res.msg
    },
    // 职称证书上传
    upSuccess5(res) {
      this.form.titleCertificate = res.msg
    },
    // 工作证明上传
    upSuccess6(res) {
      this.form.pow = res.msg
    },
    // 上传文件判断
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 5

      if (!isJPG) {
        this.$message.error('上传图片只能是 .jpg .png 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!')
      }
      return isJPG && isLt2M
    },
    // 上传失败
    upError() {
      this.$message({
        type: 'error',
        message: '上传失败',
        showClose: true,
        offset: 80,
      })
    },
    // 移除列表
    upRemve(file) {
      console.log(file)
    },
    //上传的文件改变时（覆盖原来的文件）
    upChange(file, fileList) {
      // if (fileList.length > 0) {
      //   this.fileList = [fileList[fileList.length - 1]]
      // }
    },
    next() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.form.jobUserId = localStorage.getItem('userId')
          // this.form.area = JSON.stringify(this.form.area)
          if (this.chooseCity) {
            this.form.area = this.form.area[this.form.area.length - 1]
          }
          let obj = {...this.form}
          for (let key of Object.keys(obj)) {
            let mealName = obj[key];
             if(mealName.length == 0){
              obj[key] = null
             }
          }
          addJobUserAuth(obj).then(res => {
            if (res.success) {
              this.saveFlag = true
              this.stepsActive = 1
            } else {
              this.saveFlag = false
            }
          })
        } else {
          console.log('error 基本信息验证!!')
          return false
        }
      })
      // this.stepsActive = 1
    },
  },
  filters: {},
}
</script>

<style scoped lang="scss">
.c {
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
}
.content {
  margin-top: 100px;
}
.stepsBox {
  // display: flex;
  // justify-content: center;
  // padding-top: 60px;
  // ::v-deep .el-steps {
  //   width: 450px;
  //   .is-process {
  //     .is-text {
  //       border: 2px solid #0054fe;
  //       color: #0054fe;
  //     }
  //   }
  // }

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #999;
  padding-top: 60px;
  .step1,
  .step2 {
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    span:nth-child(1) {
      width: 32px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .step1 {
    color: #666;
    span:nth-child(1) {
      color: #0054fe;
      border: 1px solid #0054fe;
    }
  }
  .step2 {
    span:nth-child(1) {
      border: 1px solid #999;
    }
  }
  .el-divider {
    flex: 1;
  }
}
.stepsActive {
  color: #000;
  span:nth-child(1) {
    border: none !important;
    background-color: #0054fe;
    color: #fff !important;
  }
}
.stepOne {
  .baseInfo {
    margin: 50px 60px;
    height: 40px;
    background: linear-gradient(
      90deg,
      rgba(247, 247, 247, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    display: flex;
    align-items: center;
    span:nth-child(1) {
      width: 10px;
      height: 100%;
      background-color: #0054fe;
      margin-right: 20px;
    }
  }
  .form {
    padding: 0 75px;
    .myInfo {
      margin-bottom: 60px;
      .myInfoLeft {
        width: 400px;
        .el-select,
        .el-cascader {
          width: 100%;
        }
      }
    }
  }
}

.stepTwo {
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    font-size: 32px;
  }
  img {
    width: 356px;
    height: 278px;
    object-fit: contain;
    margin-top: 100px;
  }
}

// .imgs {
//   img {
//     width: 300px;
//     height: 162px;
//     object-fit: contain;
//   }
// }

::v-deep .uploadBox {
  display: flex;
  .el-upload-dragger {
    border: none;
    width: 300px;
    height: 162px;
    img {
      width: 300px;
      height: 162px;
      object-fit: contain;
    }
  }
  .el-upload__tip {
    font-size: 14px;
    color: #999;
    margin-left: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    span {
      margin-top: 10px;
      line-height: normal;
    }
  }
}

.btnBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  padding-bottom: 60px;
  .el-button {
    width: 83px;
    height: 42px;
    border-radius: 2px;
  }
}
</style>
